import { BaseFeature, FeatureType } from '@core/shared/feature';
export class UsersPasswordResetQrcodeBaseFeature extends BaseFeature {
  static {
    this.featureInfo = {
      context: {"package":"pkg-common-users-password-reset-qrcode-feature","featureId":"common-users-password-reset-qrcode"},
      abilityActions: [{
        action: 'Manage',
        defaultBias: -1
      }],
      plugins: [{
        forPlatform: 'frontend',
        type: FeatureType.Permanent,
        pluginClassName: 'UsersPasswordResetQrcodeFrontendFeature'
      }, {
        forPlatform: 'frontend-management',
        type: FeatureType.Dynamic,
        pluginClassName: 'UsersPasswordResetQrcodeFrontendManagementFeature',
        simpleAbilityCheck: 'Feature.common-users.Company.Manage'
      }, {
        forPlatform: 'backend',
        type: FeatureType.Permanent,
        pluginClassName: 'UsersPasswordResetQrcodeBackendFeature'
      }]
    };
  }
  getSharedDefinition() {
    return {
      routerLinkResolvers: {
        frontend: {
          'password-reset-qrcode': token => {
            return ['user/password-reset-qrcode' + (token ? '?token=' + token : '')];
          }
        }
      }
    };
  }
}