import { BaseFeature, FeatureType } from '@core/shared/feature';
export class MpfAppBaseFeature extends BaseFeature {
  static {
    this.featureInfo = {
      context: {"package":"pkg-mpf-app-feature","featureId":"mpf-app"},
      icons: [],
      abilityActions: false,
      plugins: [{
        forPlatform: 'frontend',
        type: FeatureType.Permanent,
        pluginClassName: 'MpfAppFrontendFeature'
      }
      // {forPlatform:'admin', type:FeatureType.Permanent, pluginClassName:'MpfAppFrontendAdminFeature'},
      // {forPlatform:'backend', type:FeatureType.Permanent, pluginClassName:'MpfAppBackendFeature'},
      ]
    };
  }
  getSharedDefinition() {
    return {
      routerLinkResolvers: {
        frontend: {
          'demo': () => '/request-demo',
          'request-demo': () => '/request-demo'
        }
      }
    };
  }
}