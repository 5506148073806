/**
 * NOTE: this is an autogenerated file! do not change!
 * to change feature imports, edit /apps/<name>/shared/features.json!
 */

import {CoreBaseFeature} from "@common/core/feature";
import {AbilitiesBaseFeature} from "@common/abilities/feature";
import {MediaBaseFeature} from "@common/media/feature";
import {CloudinaryBaseFeature} from "@common/cloudinary/feature";
import {FaqBaseFeature} from "@common/faq/feature";
import {MaintenanceBaseFeature} from "@common/maintenance/feature";
import {ServerlogsBaseFeature} from "@common/serverlogs/feature";
import {NotificationsBaseFeature} from "@common/notifications/feature";
import {StatisticsBaseFeature} from "@common/statistics/feature";
import {CmsBaseFeature} from "@common/cms/feature";
import {FeedbackBaseFeature} from "@common/feedback/feature";
import {MailerBaseFeature} from "@common/mailer/feature";
import {UsersBaseFeature} from "@common/users/feature";
import {TrackingBaseFeature} from "@common/tracking/feature";
import {ChangelogBaseFeature} from "@common/changelog/feature";
import {UsersPasswordResetQrcodeBaseFeature} from "@common/users-password-reset-qrcode/feature";
import {MembershipBaseFeature} from "@common/membership/feature";
import {MpfMembershipPluginPaymentBaseFeature} from "@mpf/membership-plugin-payment/feature";
import {MpfContactBaseFeature} from "@mpf/contact/feature";
import {MpfUsersBaseFeature} from "@mpf/users/feature";
import {MpfCoursesBaseFeature} from "@mpf/courses/feature";
import {MpfDocentsBaseFeature} from "@mpf/docents/feature";
import {MpfAppBaseFeature} from "@mpf/app/feature";
import {MpfOptinUnlockVideoJumpingBaseFeature} from "@mpf/optin-unlock-video-jumping/feature";
import {CoreFrontendSharedFeature} from "@common/core/frontend-shared";
import {AbilitiesFrontendAdminFeature} from "@common/abilities/frontend-admin";
import {MediaFrontendAdminFeature} from "@common/media/frontend-admin";
import {CloudinaryFrontendAdminFeature} from "@common/cloudinary/frontend-admin";
import {FaqFrontendAdminFeature} from "@common/faq/frontend-admin";
import {MaintenanceFrontendAdminFeature} from "@common/maintenance/frontend-admin";
import {ServerlogsFrontendAdminFeature} from "@common/serverlogs/frontend-admin";
import {NotificationsFrontendAdminFeature} from "@common/notifications/frontend-admin";
import {StatisticsFrontendAdminFeature} from "@common/statistics/frontend-admin";
import {CmsFrontendAdminFeature} from "@common/cms/frontend-admin";
import {FeedbackAdminFeature} from "@common/feedback/frontend-admin";
import {MailerFrontendAdminFeature} from "@common/mailer/frontend-admin";
import {UsersAdminFeature} from "@common/users/frontend-admin";
import {TrackingFrontendAdminFeature} from "@common/tracking/frontend-admin";
import {ChangelogFrontendAdminFeature} from "@common/changelog/frontend-admin";
import {MembershipFrontendAdminFeature} from "@common/membership/frontend-admin";
import {MpfContactFrontendAdminFeature} from "@mpf/contact/frontend-admin";
import {MpfUsersFrontendAdminFeature} from "@mpf/users/frontend-admin";
import {MpfCoursesAdminFeature} from "@mpf/courses/frontend-admin";
import {MpfDocentsFrontendAdminFeature} from "@mpf/docents/frontend-admin";
import {clientCacheDevToolProvider} from "@common/core/frontend-shared";
import {DevToolProviderToken} from "@core/shared/dev-tools";
import {modelMetadataDevToolDefinition} from "@common/core/frontend-shared";
import {MediaAssetProviderCloudinaryFrontend} from "@common/cloudinary/frontend-shared";
import {MEDIA_ASSET_PROVIDER_TOKEN} from "@common/media/shared";
import {AccessUserPluginFrontend} from "@common/users/frontend-shared";
import {ACCESS_SERVICE_PLUGIN_TOKEN} from "@core/shared/access";
import {MpfPaymentFrontendMembershipPlugin} from "@mpf/membership-plugin-payment/frontend-shared";
import {MEMBERSHIP_PLUGINS_TOKEN} from "@common/membership/shared";
import { CoreFeatureModule } from "@core/frontend-shared";

export default [
	// include base feature info
	CoreFeatureModule.forRoot([
		CoreBaseFeature,
		AbilitiesBaseFeature,
		MediaBaseFeature,
		CloudinaryBaseFeature,
		FaqBaseFeature,
		MaintenanceBaseFeature,
		ServerlogsBaseFeature,
		NotificationsBaseFeature,
		StatisticsBaseFeature,
		CmsBaseFeature,
		FeedbackBaseFeature,
		MailerBaseFeature,
		UsersBaseFeature,
		TrackingBaseFeature,
		ChangelogBaseFeature,
		UsersPasswordResetQrcodeBaseFeature,
		MembershipBaseFeature,
		MpfMembershipPluginPaymentBaseFeature,
		MpfContactBaseFeature,
		MpfUsersBaseFeature,
		MpfCoursesBaseFeature,
		MpfDocentsBaseFeature,
		MpfAppBaseFeature,
		MpfOptinUnlockVideoJumpingBaseFeature
	],[
		{token:DevToolProviderToken, value:clientCacheDevToolProvider},
		{token:DevToolProviderToken, value:modelMetadataDevToolDefinition},
		{token:MEDIA_ASSET_PROVIDER_TOKEN, value:MediaAssetProviderCloudinaryFrontend},
		{token:ACCESS_SERVICE_PLUGIN_TOKEN, value:AccessUserPluginFrontend},
		{token:MEMBERSHIP_PLUGINS_TOKEN, value:MpfPaymentFrontendMembershipPlugin}
	]),

	// load feature plugins for this platform
	CoreFrontendSharedFeature,
	AbilitiesFrontendAdminFeature,
	MediaFrontendAdminFeature,
	CloudinaryFrontendAdminFeature,
	FaqFrontendAdminFeature,
	MaintenanceFrontendAdminFeature,
	ServerlogsFrontendAdminFeature,
	NotificationsFrontendAdminFeature,
	StatisticsFrontendAdminFeature,
	CmsFrontendAdminFeature,
	FeedbackAdminFeature,
	MailerFrontendAdminFeature,
	UsersAdminFeature,
	TrackingFrontendAdminFeature,
	ChangelogFrontendAdminFeature,
	MembershipFrontendAdminFeature,
	MpfContactFrontendAdminFeature,
	MpfUsersFrontendAdminFeature,
	MpfCoursesAdminFeature,
	MpfDocentsFrontendAdminFeature
] as const;

