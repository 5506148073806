import { ClassTransformExecutor } from './class-transform.executor';
/**
 * ClassTransformService can be injected!
 * (classes that will be injected are BackendClassTransformService/FrontendClassTransformService)
 * (these child classes currently live in model-factory-backend/frontend-provider.ts!)
 */
export class ClassTransformService {
  static getMetadataStorage() {
    return ClassTransformExecutor.getMetadataStorage();
  }
  getMetadataStorage() {
    return ClassTransformExecutor.getMetadataStorage();
  }
  instanceToPlain(object, options) {
    return ClassTransformExecutor.instanceToPlain(object, this.getTransformDeps(), options);
  }
  plainToInstance(cls, plain, options) {
    return ClassTransformExecutor.plainToInstance(cls, plain, this.getTransformDeps(), options);
  }
  createOptionsFor(cls, object, type, customOptions = {}) {
    return ClassTransformExecutor.createOptionsFor(cls, object, type, customOptions, this.getTransformDeps());
  }
  /**
   * legacy methods. Not used by us, but required for compatibility with RequestValidationPipe.loadTransformer (TransformerPackage)
   */
  classToPlain(object, options) {
    return this.instanceToPlain(object, options);
  }
  plainToClass(cls, object, options) {
    return this.plainToInstance(cls, object, options);
  }
}