import { Component } from '@angular/core';
import { UserItem } from '@common/users/shared';
import { AccessState } from '@core/frontend-shared';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'mpf-admin-home',
  template: `
	<h2 *ngIf="userData$|async; let userData">Willkommen zurück,<br> <b>{{userData?.firstName}} {{userData?.lastName}}</b>!</h2>
  `,
  styles:[`
	:host {display:flex;justify-content:center;align-items:center;}
	h2 { opacity:30%; font-size:42px; }
  `]
})
export class HomeComponent {
	@Select(AccessState.accessData('user')) userData$:Observable<UserItem>;

}
