import { CrudRequestType } from '@core/shared/request';
export var CrudRequestHelper;
(function (CrudRequestHelper) {
  function requestTypeIsRead(type) {
    return type === CrudRequestType.ReadMany || type === CrudRequestType.ReadOne;
  }
  CrudRequestHelper.requestTypeIsRead = requestTypeIsRead;
  function requestTypeAffectsMany(type) {
    return type === CrudRequestType.ReadMany || type === CrudRequestType.CreateMany;
  }
  CrudRequestHelper.requestTypeAffectsMany = requestTypeAffectsMany;
})(CrudRequestHelper || (CrudRequestHelper = {}));