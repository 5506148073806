import { ItemRestraints } from '@core/shared/restraints';
export class DocentRestraints extends ItemRestraints {
  canViewItem(item) {
    return true;
  }
  canCreateItem(item) {
    return true;
  }
  canToggleItem(item) {
    return true;
  }
  canUpdateItem(item, oldItem) {
    return true;
  }
  canRemoveItem(item) {
    return true;
  }
}