import { ItemRestraints } from '@core/shared/restraints';
export class NotificationRestraints extends ItemRestraints {
  canViewItem(item) {
    return this.requireRole('REGISTERED');
  }
  canCreateItem(item) {
    if (this.hasRole('ADMINISTRATOR') || this.hasRole('MANAGER')) return true;
    return 'Sie haben keine Berechtigung, Benachrichtigungen zu erstellen.';
  }
  canToggleItem(item) {
    return true;
  }
  canUpdateItem(item, oldItem) {
    return this.requireRole('ADMINISTRATOR');
  }
  canRemoveItem(item) {
    return 'notifications cannot be removed';
  }
}