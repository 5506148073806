import { BaseFeature } from '@core/shared/feature';
import { MEMBERSHIP_PLUGINS_TOKEN } from '@common/membership/shared';
export class MpfMembershipPluginPaymentBaseFeature extends BaseFeature {
  static {
    this.featureInfo = {
      context: {"package":"pkg-mpf-membership-plugin-payment-feature","featureId":"mpf-membership-plugin-payment"},
      icons: [],
      abilityActions: false,
      plugins: [
        // {forPlatform:'frontend', type:FeatureType.Permanent, pluginClassName:'MpfMembershipPluginPaymentFrontendFeature'},
        // {forPlatform:'admin', type:FeatureType.Permanent, pluginClassName:'MpfMembershipPluginPaymentFrontendAdminFeature'},
        // {forPlatform:'backend', type:FeatureType.Permanent, pluginClassName:'MpfMembershipPluginPaymentBackendFeature'},
        // {forPlatform:'backend-admin', type:FeatureType.Permanent, pluginClassName:'MpfMembershipPluginPaymentBackendAdminFeature'},
      ],
      providers: [{
        forPlatform: 'frontend',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'MpfPaymentFrontendMembershipPlugin',
        importFrom: 'frontend-shared'
      }, {
        forPlatform: 'admin',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'MpfPaymentFrontendMembershipPlugin',
        importFrom: 'frontend-shared'
      }, {
        forPlatform: 'backend',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'MpfPaymentMembershipPlugin',
        importFrom: 'shared'
      }]
    };
  }
  getSharedDefinition() {
    return {
      routerLinkResolvers: {
        frontend: {}
      }
    };
  }
}