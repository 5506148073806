// push <value> to array inside <map> at index <key>
export function arrayMapMergeOne(map, key, value) {
  if (map.has(key)) {
    map.set(key, [...map.get(key), value]);
  } else {
    map.set(key, [value]);
  }
}
// push many <values> to array inside <map> at index <key>
export function arrayMapMergeMany(map, key, value) {
  if (map.has(key)) {
    map.set(key, [...map.get(key), ...value]);
  } else {
    map.set(key, [...value]);
  }
}