import { BaseFeature, FeatureType, OptInFeatureScope } from '@core/shared/feature';
export class MpfOptinUnlockVideoJumpingBaseFeature extends BaseFeature {
  static {
    this.featureInfo = {
      context: {"package":"pkg-mpf-optin-unlock-video-jumping-feature","featureId":"mpf-optin-unlock-video-jumping"},
      abilityActions: false,
      plugins: [{
        forPlatform: 'frontend',
        importFrom: 'frontend-shared',
        pluginClassName: 'MpfOptInUnlockVideoJumpingFrontendFeature',
        type: FeatureType.OptIn,
        title: 'Video-Navigation entsperren',
        description: 'Schaltet die Navigation innerhalb von Kursszenen frei, sodass vorgespult werden kann.',
        scope: OptInFeatureScope.User
      }]
    };
  }
  getSharedDefinition() {
    return {};
  }
}