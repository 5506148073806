import '../types';
// a sequence of invisible UTF chars is used to mark the string as translateable
// (zero-width spacings)
const I18nStringIdentifier = "\uFEFF\u200B\uFEFF";
// as rimitives cannot store extra data, we use a map to store which key belongs to which defaultText value.
const keyMap = new Map();
export function getOptionalI18nKey(str) {
  if (typeof str !== 'string') return null;
  if (!str.endsWith(I18nStringIdentifier)) {
    return null;
  } else if (keyMap.has(str)) {
    return keyMap.get(str);
  } else {
    return str;
  }
}
/**
 * the globally available I18N function allows to "mark" a bit of a text to be translateable.
 * actively executed translation requires access to injected services, may require async logic etc.
 * as this is not always simple to achieve, think about data within decorators and such.
 * The I18N function will add some invisible Unicode chars so that handlers will later be able to identify it as translateable.
 *
 * notes:
 * - invisibile unicode chars could be used. e.g. U+200B ZERO WIDTH SPACE
 * 	- when using primitive with extra chars, it is not possible to store additional ID / settings data.
 * 	- a registration map for extra data could be written. But it would be keyed with primitives, so duplicate values may conflict.
 */
globalThis.i18n = (key, defaultText) => {
  if (!defaultText) defaultText = key;
  const enhancedDefault = defaultText + I18nStringIdentifier;
  keyMap.set(enhancedDefault, key);
  return enhancedDefault;
};