import { PATH_PARAM_KEY, getBaseModelFromEditorModel, getCrudEndpoint, isCrudClientCacheEnabled } from '@core/shared/model';
import { ClientCacheRequestMode, getClientCacheModelId, getHeadersForEnabledClientCache, getUtilityHeadersForClientCache } from '../../client-cache';
import { CrudQueryBuilder } from '../crud-query-builder';
// Note that the event is not fired for READ actions!
export class RequestCompletedEvent {
  constructor(type, response) {
    this.type = type;
    this.response = response;
  }
}
// not injected, use CrudServiceFactory to create!
export class BaseCrudService {
  constructor(modelFactoryProvider, clientCache, state) {
    this.modelFactoryProvider = modelFactoryProvider;
    this.clientCache = clientCache;
    this.state = state;
    this.query = new CrudQueryBuilder();
    /**
     * PathParams are helpers for resolving dynamic API paths, e.g. /courses/:courseId/scenes/:id.
     * Params like :courseId can be specified in two ways:
     * - in ItemModel (example: when patching a model and it contains the courseId property, its value will be used)
     * - by pathParamsMap (example: A route resolver might specify parent route params and add it to pathParamsMap)
     */
    this.pathParamsMap = {};
    this.clientCacheConfig = false;
    this.pendingRequests$ = null;
    this.loadingState$ = null;
  }
  setModel(Model) {
    this.ItemFactory = this.modelFactoryProvider.createModelFactory(Model);
    this.pendingRequests$ = this.state.getPendingRequests$(Model);
    this.loadingState$ = this.state.getLoadingState$(Model);
  }
  listenToCrudStateEvents$() {
    return this.state.listenTo(this.ItemFactory.Model);
  }
  useCrudEndpoint(name) {
    // cannot use assertInitialize as this method will set the second condition required to let the check pass
    if (!this.ItemFactory?.Model) throw new Error('called useCrudEndpoint before Model is defined!');
    this.endpointName = name;
    const endpointInfo = getCrudEndpoint(this.ItemFactory.Model, name);
    this.setApiPath(endpointInfo.path);
    const enableCache = !!endpointInfo.options.clientCache;
    if (enableCache) this.clientCacheConfig = endpointInfo.options.clientCache;else this.clientCacheConfig = false;
  }
  setPathParams(paramMap) {
    this.pathParamsMap = {
      ...this.pathParamsMap,
      ...paramMap
    };
  }
  setApiPath(path) {
    this.apiPath = path;
    // eslint-disable-next-line no-useless-escape
    const params = this.apiPath.match(/(^|\/):([^\/\s]+)/gm);
    if (params) {
      this.apiPathParams = params.map(p => p.substring(2));
    } else {
      this.apiPathParams = [];
    }
    // the standard :id param is required for any item route!
    this.apiPathParams.push('id');
  }
  bindRequest(request, hot, type, info = {}) {
    return this.state.bindRequest(this.ItemFactory.Model, request, hot, type, info);
  }
  // ---- Other -------------------------------------------------------
  assertInitialized() {
    if (!this.ItemFactory) throw new Error('Tried to use CrudService before a Model has been set!');
  }
  getHeaders(requestType, options = {}) {
    /**
     * difficulty here: clientCache may be disabled for this service or request,
     * but it still must respect incoming cache wiping request.
     * thus, headers from getUtilityHeadersForClientCache will always be passed through.
     */
    let headers = {
      ...getUtilityHeadersForClientCache(options.cache)
    };
    const enableCache = !!this.clientCacheConfig && options.cache !== ClientCacheRequestMode.skipCache;
    if (enableCache) {
      const config = this.clientCacheConfig;
      const enabledForRequest = isCrudClientCacheEnabled(config, this.endpointName, requestType);
      if (enabledForRequest) {
        const tags = ['path:' + this.apiPath, 'crud:' + requestType, getClientCacheModelId(this.ItemFactory.Model)];
        headers = {
          ...headers,
          ...getHeadersForEnabledClientCache(this.clientCacheConfig, tags, options.cache)
        };
      }
    }
    return headers;
  }
  processItemData(data) {
    // add URL params as specified by pathParamValues into item model.
    let item;
    if (data instanceof this.ItemFactory.Model) {
      item = data;
    } else {
      item = this.ItemFactory.fromData(data);
    }
    const dataToAdd = {};
    const pathParamValues = Reflect.getMetadata(PATH_PARAM_KEY, item);
    for (const k in pathParamValues) {
      if (Object.prototype.hasOwnProperty.call(item, k)) {
        // check if property can be overwritten
        if (typeof item[k] === 'undefined' || pathParamValues[k].overwrite) {
          // check if a matching value is available in pathParamsMap
          if (typeof this.pathParamsMap[pathParamValues[k].name] !== 'undefined') {
            dataToAdd[k] = this.pathParamsMap[pathParamValues[k].name];
          }
        }
      }
    }
    // eslint-disable-next-line guard-for-in
    for (const key in dataToAdd) {
      item[key] = dataToAdd[key];
    }
    return item;
  }
  generateApiPath(data = {}, additionalPath) {
    if (typeof additionalPath === 'number') additionalPath = additionalPath.toString();
    this.assertReady();
    let path = this.apiPath;
    if (additionalPath) path = path + '/' + additionalPath;
    // Note: the regex replace must ALWAYS execute. it does not only replace pathParams.
    // it also handles :id which is present on any item route!
    // find /:name/ query parts and replace them
    // eslint-disable-next-line no-useless-escape
    path = path.replace(/(^|\/):([^\/\s]+)/gm, (match, p1, p2) => {
      let value;
      if (typeof this.pathParamsMap[p2] !== 'undefined') {
        value = this.pathParamsMap[p2];
      } else if (typeof data[p2] !== 'undefined') {
        value = data[p2];
      } else {
        throw new Error('ItemService was not able to build ApiPath. Parameter named "' + p2 + '" is missing');
      }
      if (typeof value === 'number') return '/' + value.toString();else return '/' + value;
    });
    // console.log('generated api path',this.pathParamsMap,data,additionalPath,this.apiPath,path)
    return path;
  }
  clearItemClientCache() {
    // the configurated model may be an editor model. 
    // Cache must be invalidated based on the ID of the actual item model, so first we need to make sure we got the right one.
    const BaseModel = getBaseModelFromEditorModel(this.ItemFactory.Model);
    this.clientCache.clearCacheForModel(BaseModel);
  }
  assertReady() {
    if (!this.apiPath) throw new Error("Tried to use CrudService before calling useCrudEndpoint!");
  }
}