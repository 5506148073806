import * as deepmerge from 'deepmerge';
import { getDotProp, setDotProp } from '../utils';
import { transformEnvNameToConfigName, transformKeysToLowerCase } from './helpers';
import '../types';
// sets nodejs traces to be more detailed than default 10 lines
Error['stackTraceLimit'] = 25;
export const APP_VERSION = {
  "version": VERSION,
  "timestamp": TIMESTAMP
};
/*
 * WARNING: Different behavior depending on platform!
 * - backend: Before booting nestJs, Config.initialize will be called from main.ts
 * - frontend: During app bootstrapping, an APP_INITIALIZER will fetch config from server and pass it to Config.initialize
 */
export class Config {
  static {
    this.configSources = {
      runtimeConfig: null,
      staticConfig: null
    };
  }
  static {
    this.overrides = new Map();
  }
  static {
    this.initializedStaticConfig = false;
  }
  static {
    this.initializedRuntimeConfig = false;
  }
  static get isInitialized() {
    return this.initializedStaticConfig && this.initializeRuntimeConfig;
  }
  static initializeRuntimeConfig(configData) {
    // console.warn('Config.initialize called!',staticConfig)
    this.buildRuntimeConfig(configData);
    this.initializedRuntimeConfig = true;
  }
  static initializeStaticConfig(configData) {
    // console.warn('Config.initialize called!',staticConfig)
    if (!this.initializedRuntimeConfig) throw new Error('please call initializeRuntimeConfig before initializeStaticConfig');
    if (this.configSources.staticConfig && Object.entries(this.configSources.staticConfig).length > 0) return;
    this.configSources.staticConfig = transformKeysToLowerCase(configData);
    this.mergeConfigSources();
    this.overrides = new Map();
    this.initializedStaticConfig = true;
  }
  static buildRuntimeConfig(configData) {
    this.configSources.runtimeConfig = {};
    if (!configData) return;
    // check environment variables for \n and trim where needed
    for (const path in configData) {
      if (configData[path].indexOf('\\n') > -1) {
        configData[path] = configData[path].replace(/\\n/g, "\n");
      }
    }
    for (let path of Object.keys(configData)) {
      if (path) {
        let val = configData[path];
        // all env values are STRINGS! Need to convert.
        // support boolean and integers.
        if (val === 'true') val = true;else if (val === 'false') val = false;else if (/^\d+$/.test(val)) val = parseInt(val, 10);
        // transform GROUP_MYNESTED_VALUE to group.mynested.value
        // NOTE: Expected to not change anything anymore, because shell script already handles the transformation
        path = transformEnvNameToConfigName(path);
        // deeply set props specified with deep.value.path
        setDotProp(this.configSources.runtimeConfig, path, val);
      }
    }
  }
  static mergeConfigSources() {
    this.mergedConfig = deepmerge.all([this.buildBaseConfig(), this.configSources.staticConfig, this.configSources.runtimeConfig]);
    this.validateConfiguration();
  }
  static buildBaseConfig() {
    return {
      version: APP_VERSION.version,
      versioninfo: APP_VERSION
    };
  }
  static validateConfiguration() {
    const appRef = this.mergedConfig.app;
    if (!appRef) return; // should not happen in production but might be the case in testing scenarios
    // check given host strings for validity
    // LOWERCASE!
    ['frontendhost', 'adminhost', 'apihost'].forEach(key => {
      const host = appRef[key];
      // its possible the property isn't set in some situations
      if (!host) return;
      if (host.startsWith('http')) throw new Error('config error: app.' + key + ' must not contain protocol!');
      if (host.startsWith('//')) throw new Error('config error: app.' + key + ' must not start with //!');
      if (host.substr(-1) === '/') throw new Error('config error: app.' + key + ' must not end with /!');
    });
  }
  static getProperty(path) {
    if (!this.mergedConfig) throw new Error('trying to get config property before mergedEnv is ready!');
    if (this.overrides.has(path)) return this.overrides.get(path);else return getDotProp(this.mergedConfig, path);
  }
  static get(name, defaultValue) {
    if (!this.isInitialized) throw new Error('Config used before initialization!');
    name = name.toLowerCase();
    const match = this.getProperty(name);
    let val;
    if (match === null) {
      val = defaultValue;
    } else {
      val = typeof match === 'function' ? match(Config) : match;
    }
    // console.log('config get',name,val)
    return val;
  }
  // ---- OVERRIDES ------------------
  static setOverride(name, value) {
    this.overrides.set(name.toLowerCase(), value);
  }
  static clearOverride(name) {
    this.overrides.delete(name.toLowerCase());
  }
  // ---- DEV+TESTING ONLY! ------------------
  static getDebugData() {
    const data = deepmerge.all([{}, this.mergedConfig]);
    this.overrides.forEach((value, prop) => {
      setDotProp(data, prop, value);
    });
    return data;
  }
  static getConfigSources() {
    return this.configSources;
  }
  static getMergedConfig() {
    return this.mergedConfig;
  }
  static reset() {
    this.configSources = {
      runtimeConfig: null,
      staticConfig: null
    };
    this.mergedConfig = undefined;
    this.overrides = new Map();
    this.initializedStaticConfig = false;
    this.initializedRuntimeConfig = false;
  }
}