import { arraySplitByCondition, arrayMapMergeOne } from '../utils';
export var AbilityUtils;
(function (AbilityUtils) {
  function abilitiesToActionIdMap(abilities) {
    return abilities.reduce((map, ability) => {
      map[getActionId(ability)] = ability.bias;
      return map;
    }, {});
  }
  AbilityUtils.abilitiesToActionIdMap = abilitiesToActionIdMap;
  function abilitiesMapToGrantedList(abilities) {
    return Object.entries(abilities).filter(entry => entry[1] > 0).map(entry => entry[0]);
  }
  AbilityUtils.abilitiesMapToGrantedList = abilitiesMapToGrantedList;
  function getActionId(ability) {
    return `${ability.subjectType}.${ability.subject}.${ability.action}`;
  }
  AbilityUtils.getActionId = getActionId;
  function getActionIdInfo(abilityActionId) {
    const parts = abilityActionId.split('.');
    if (parts.length < 3) throw new Error('abilityActionId is not valid: ' + abilityActionId);
    return {
      type: parts.shift(),
      subject: parts.shift(),
      action: parts.join('.')
    };
  }
  AbilityUtils.getActionIdInfo = getActionIdInfo;
  function buildActionId(subjectId, action) {
    return `${subjectId.type}.${subjectId.subject}.${action}`;
  }
  AbilityUtils.buildActionId = buildActionId;
  function matchAbilities(a1, a2) {
    return a1.subjectType === a2.subjectType && a1.subject === a2.subject && a1.action === a2.action;
  }
  AbilityUtils.matchAbilities = matchAbilities;
  function matchSubjectIds(s1, s2) {
    return s1.type === s2.type && s1.subject === s2.subject;
  }
  AbilityUtils.matchSubjectIds = matchSubjectIds;
  function findSubjectInList(id, list) {
    return list.find(id2 => matchSubjectIds(id, id2));
  }
  AbilityUtils.findSubjectInList = findSubjectInList;
  function mergeSkillsetsToAbilities(skillsets) {
    const abilities = skillsets.reduce((a, skillset) => {
      if (skillset.enabled) a.push(...skillset.abilities);
      return a;
    }, []);
    const cleaned = cleanupRedundantAbilities(abilities);
    return cleaned;
  }
  AbilityUtils.mergeSkillsetsToAbilities = mergeSkillsetsToAbilities;
  function cleanupRedundantAbilities(abilities) {
    const abilitiesMap = new Map();
    abilities.forEach(ability => {
      // if(!ability.enabled) return;
      const actionId = getActionId(ability);
      arrayMapMergeOne(abilitiesMap, actionId, ability);
    });
    const mergedAbilities = [];
    // filter available abilities:
    // - all conditional abilities must be included
    // - unconditional abilities can be compared, only include the most relevant one (strongest bias/priority)
    for (const [actionId, actionIdAbilities] of abilitiesMap) {
      // Using the default iterator (could be `map.entries()` instead)
      const [unconditional, conditional] = arraySplitByCondition(actionIdAbilities, ability => {
        return !ability.conditions;
      });
      unconditional.sort((a, b) => {
        const val = Math.abs(b.bias) - Math.abs(a.bias);
        if (val === 0) return a.bias - b.bias; // if absolute values are equal, negative value should be sorted before positive
        return val;
      });
      // console.log('unconditionals for '+actionId, unconditional);
      mergedAbilities.push(unconditional[0], ...conditional);
    }
    return mergedAbilities;
  }
  AbilityUtils.cleanupRedundantAbilities = cleanupRedundantAbilities;
  function validateAbility(abilityObj, actionId, abilityRegistry) {
    const issues = [];
    if (actionId && abilityRegistry) {
      const issuesInActionId = checkValidAbilityAction(abilityRegistry, actionId);
      if (Array.isArray(issuesInActionId)) {
        issues.push(...issuesInActionId);
        return issues;
      }
    }
    if (typeof abilityObj !== 'object' || abilityObj === null) {
      issues.push('Ability data is no object');
      return issues;
    }
    if (actionId) {
      const actionIdInfo = AbilityUtils.getActionIdInfo(actionId);
      if (abilityObj.action !== actionIdInfo.action) {
        issues.push(`Inconsistent action value in Ability data vs actionId: ${abilityObj.action} != ${actionIdInfo.action}`);
        return issues;
      }
      if (abilityObj.subject !== actionIdInfo.subject) {
        issues.push(`Inconsistent subject value in Ability data vs actionId: ${abilityObj.subject} != ${actionIdInfo.subject}`);
        return issues;
      }
      if (abilityObj.subjectType !== actionIdInfo.type) {
        issues.push(`Inconsistent type value in Ability data vs actionId: ${abilityObj.subjectType} != ${actionIdInfo.type}`);
        return issues;
      }
    }
    if (typeof abilityObj.bias !== 'number') {
      issues.push(`Invalid "bias" value: ${abilityObj.bias}`);
      return issues;
    }
    if (typeof abilityObj.conditions !== 'object') {
      issues.push(`Invalid "conditions" value: ${abilityObj.conditions}`);
      return issues;
    }
    if (typeof abilityObj.fields !== 'undefined' && !Array.isArray(abilityObj.fields)) {
      issues.push(`Invalid "fields" value: ${abilityObj.fields}`);
      return issues;
    }
    if (typeof abilityObj.enabled !== 'undefined' && typeof abilityObj.enabled !== 'boolean') {
      issues.push(`Invalid "enabled" value: ${abilityObj.enabled}`);
      return issues;
    }
  }
  AbilityUtils.validateAbility = validateAbility;
  function checkValidAbilityAction(abilitySubjectsRegistry, actionIdOrIds, origin) {
    const registeredActions = abilitySubjectsRegistry.getAllActions();
    const actions = Array.isArray(actionIdOrIds) ? actionIdOrIds : [actionIdOrIds];
    const issues = actions.reduce((issueList, action) => {
      if (!registeredActions.includes(action)) {
        issueList.push('detected unknown AbilityAction "' + action + '"' + (origin ? ', originating from ' + origin : ''));
      }
      return issueList;
    }, []);
    if (issues.length > 0) return issues;
    return true;
  }
  AbilityUtils.checkValidAbilityAction = checkValidAbilityAction;
  function uniqueSubjectsArray(inputSubjects) {
    const uniqueSubjects = [];
    inputSubjects.forEach(subject => {
      const foundInTargetArray = uniqueSubjects.find(s2 => {
        return matchSubjectIds(subject, s2);
      });
      if (!foundInTargetArray) uniqueSubjects.push(subject);
    });
    return uniqueSubjects;
  }
  AbilityUtils.uniqueSubjectsArray = uniqueSubjectsArray;
})(AbilityUtils || (AbilityUtils = {}));