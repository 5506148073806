import { ConfigService } from '@core/shared/config';
import { ClassTransformService, ModelFactoryProvider, modelFactoryProviderFactory } from '@core/shared/model';
import { Store } from '@ngxs/store';
import { AccessState } from '../access';
export class FrontendClassTransformService extends ClassTransformService {
  constructor(config, store) {
    super();
    this.config = config;
    this.store = store;
  }
  getTransformDeps() {
    return {
      config: this.config,
      abilities: this.store.selectSnapshot(AccessState.abilities),
      role: this.store.selectSnapshot(AccessState.role)
    };
  }
}
export function classTransformServiceFrontendFactory(config, store) {
  return new FrontendClassTransformService(config, store);
}
export function modelFactoryFrontendProviders() {
  return [{
    provide: ClassTransformService,
    useFactory: classTransformServiceFrontendFactory,
    deps: [ConfigService, Store]
  }, {
    provide: ModelFactoryProvider,
    useFactory: modelFactoryProviderFactory,
    deps: [ClassTransformService]
  }];
}