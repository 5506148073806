import { AppRequestService } from '@core/frontend-shared';
import { Store } from '@ngxs/store';
const ACCESS_USER_PLUGIN_ID = 'user';
export const AccessUserPluginFrontendDataAccessors = {
  user: (getPluginData, info) => {
    return getPluginData()?.user || null;
  },
  userId: (getPluginData, info) => {
    return getPluginData()?.user?.id || null;
  },
  abilities: (getPluginData, info) => {
    return getPluginData()?.abilities;
  },
  isManager: (getPluginData, info) => {
    return info.abilities["Programmatic.common-users.IsCompanyManager"] || 0 > 0;
  },
  company: (getPluginData, info) => {
    return getPluginData()?.company || null;
  },
  companyManagers: (getPluginData, info) => {
    return getPluginData()?.company?.managers;
  },
  companyManagerIds: (getPluginData, info) => {
    return getPluginData()?.company?.managerIds;
  },
  managedCompany: (getPluginData, info) => {
    return getPluginData()?.managedCompany || null;
  },
  managedUserIds: (getPluginData, info) => {
    return getPluginData()?.managedUserIds || null;
  },
  managesUserId: (getPluginData, info, id) => {
    return (getPluginData()?.managedUserIds || []).includes(id);
  }
};
export class AccessUserPluginFrontend {
  constructor(injector) {
    this.injector = injector;
    this.pluginId = ACCESS_USER_PLUGIN_ID;
    this.dataAccessors = AccessUserPluginFrontendDataAccessors;
    this.api = injector.get(AppRequestService);
    this.store = injector.get(Store);
  }
  initialize(access) {
    this.access = access;
  }
  assertPluginIsReady() {
    return Promise.resolve(true);
  }
}