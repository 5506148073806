import { UserBasedItemRestraints } from '@common/users/shared';
export class MandatoryCourseGroupRestraints extends UserBasedItemRestraints {
  canViewItem(item) {
    if (this.userBelongsToCompany(item.companyId)) return true;
    return "Sie haben keine Berechtigung, diese Pflichtkursgruppe einzusehen.";
  }
  canCreateItem(item) {
    return this.hasRole('MANAGER');
  }
  canToggleItem(item) {
    // currently it is not possible to toggle MCGs
    // if(this.userManagesCompany(item.companyId)) return true;
    // if(this.currentUserIsOwner(item)) return true;
    return false;
  }
  canUpdateItem(item, oldItem) {
    if (!item.companyId) throw new Error('Cannot update MandatoryCourseGroupItem with unknown companyId!');
    if (this.userManagesCompany(item.companyId)) return true;
    if (this.hasRole('ADMINISTRATOR')) return true;
    return "Sie haben keine Berechtigung, diese Pflichtkursgruppe zu bearbeiten.";
  }
  canRemoveItem(item) {
    if (this.userManagesCompany(item.companyId)) return true;
    return "Sie haben keine Berechtigung, diese Pflichtkursgruppe zu löschen.";
  }
}