import { isUserRole, roleCheck, UserRole } from '../access/user-role';
import { AbilityUtils } from './ability.utils';
export function simpleAbilityRequirementToStruct(requirement) {
  let check;
  if (Array.isArray(requirement)) check = {
    allow: [requirement]
  };else if (typeof requirement === 'object') check = requirement;else if (typeof requirement === 'string') check = {
    allow: [requirement]
  };else throw new Error('@AccessRestriction: Invalid Configuration: ' + JSON.stringify(requirement));
  return check;
}
export function simpleAbilityStructExtractActions(requirement) {
  const list = [];
  ['allow', 'except'].forEach(key => {
    if (!requirement[key]) return;
    const orList = Array.isArray(requirement[key]) ? requirement[key] : [requirement[key]];
    orList.forEach(data => {
      const andList = Array.isArray(data) ? data : [data];
      andList.forEach(abilityOrUserRole => {
        if (!isUserRole(abilityOrUserRole)) {
          list.push(abilityOrUserRole);
        }
      });
    });
  });
  return list;
}
export function assertValidAbilityActionsInSimpleAbilityRequirement(abilitySubjectsRegistry, requirement, origin) {
  const issues = checkValidAbilityActionsInSimpleAbilityRequirement(abilitySubjectsRegistry, requirement, origin);
  if (Array.isArray(issues)) {
    issues.forEach(info => console.error(info));
  }
}
export function checkValidAbilityActionsInSimpleAbilityRequirement(abilitySubjectsRegistry, requirement, origin) {
  const struct = simpleAbilityRequirementToStruct(requirement);
  const actions = simpleAbilityStructExtractActions(struct);
  return AbilityUtils.checkValidAbilityAction(abilitySubjectsRegistry, actions, origin);
}
export function simpleAbilityCheck(userRole, grantedAbilities, requirement, respectSuperuser = true) {
  if (respectSuperuser && userRole === UserRole.SUPERUSER) return true;
  if (!requirement) return true;
  const check = simpleAbilityRequirementToStruct(requirement);
  const matchesAllow = check.allow ? fullfillsSimpleAbilityList(userRole, grantedAbilities, check.allow) : true;
  const matchesExcept = check.except ? fullfillsSimpleAbilityList(userRole, grantedAbilities, check.except) : false;
  return matchesAllow && !matchesExcept;
}
export function fullfillsSimpleAbilityList(userRole, grantedAbilities, required, respectSuperuser = true) {
  if (respectSuperuser && userRole === UserRole.SUPERUSER) return true;
  // check if actually any access checks are required
  if (!required || required.length === 0) return true;
  // the check passes if any of the <or> entries in required evaluates to true
  return required.some(matchingData => {
    const andList = Array.isArray(matchingData) ? matchingData : [matchingData];
    return andList.every(abilityOrUserRole => {
      if (isUserRole(abilityOrUserRole)) {
        return roleCheck(abilityOrUserRole, userRole);
      } else {
        if (Array.isArray(grantedAbilities)) return grantedAbilities.includes(abilityOrUserRole);else return (grantedAbilities[abilityOrUserRole] || 0) > 0;
      }
    });
  });
}