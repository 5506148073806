import { MembershipHelper, MembershipPlugin } from '@common/membership/shared';
import { getDotProp, objectWithout } from '@core/shared/utils';
import { of } from 'rxjs';
import { MembershipRegistrationDataMpfPaymentModel } from './models';
export class MpfPaymentMembershipPlugin extends MembershipPlugin {
  getFormDataModel() {
    return MembershipRegistrationDataMpfPaymentModel;
  }
  static getInfo() {
    return {
      id: 'mpf-payment',
      name: 'Jahresmitgliedschaft für meinPflegeforum',
      description: "Jahresmitgliedschaft für meinPflegeforum",
      paymentRequired: true
    };
  }
  calculateValidUntil(existingMembershipValidity) {
    return MembershipHelper.calculateValidUntil(365, existingMembershipValidity);
  }
  getLabelForField(fieldId) {
    switch (fieldId) {
      case 'email':
        return 'E-Mail-Adresse';
      case 'phone':
        return 'Telefonnummer (optional)';
      case 'firstName':
        return 'Vorname';
      case 'lastName':
        return 'Nachname';
      case 'password':
        return 'Passwort';
      case 'password2':
        return 'Passwort wiederholen';
      case 'birthdate':
        return 'Geburtsdatum';
      case 'address':
        return 'Straße und Hausnr.';
      case 'zipcode':
        return 'Postleitzahl';
      case 'city':
        return 'Ort';
    }
    return '<' + fieldId + '>';
  }
  generateSummaryData(data, withAccountData = false) {
    const invoiceRows = this.getRegistrationDataFields('invoice').map(field => {
      return {
        label: this.getLabelForField(field),
        value: this.getDisplayValue(field, data[field])
      };
    });
    const invoiceSummary = {
      id: 'invoice',
      title: 'Rechnungsangaben',
      rows: invoiceRows
    };
    const summary = [invoiceSummary];
    if (withAccountData) {
      const accountRows = this.getRegistrationDataFields('account').map(field => {
        return {
          label: this.getLabelForField(field),
          value: this.getDisplayValue(field, data[field])
        };
      });
      const accountSummary = {
        id: 'account',
        title: 'Ihr Account',
        rows: accountRows
      };
      summary.push(accountSummary);
    }
    return of(summary);
  }
  generateReportData(memberships, detailed) {
    const reports = [];
    const info = this.getStaticPluginInfo();
    reports.push({
      name: info.name,
      id: '0',
      membershipType: info.id,
      entries: memberships
    });
    return reports;
  }
  getCart() {
    return [{
      id: 'personal_membership_1yr',
      title: 'Jahresmitgliedschaft meinPflegeforum',
      value: 96 / 1.19
    }];
  }
  getReportColumnHeaders() {
    const fields = this.getRegistrationDataFields('invoice');
    return fields;
  }
  getReportColumnValues(membership, data) {
    const fields = this.getRegistrationDataFields('invoice');
    return fields.map(fieldName => {
      return getDotProp(data, fieldName) || '-';
    });
  }
  getRegistrationDataFields(type) {
    if (type === 'invoice') return ['firstName', 'lastName', 'address', 'zipcode', 'city'];
    // special situation for this plugin: mpf is not set to use usernameIsEmail mode.
    // still, membership accounts will use email as username. For this reason, account fields does not include username.
    if (type === 'account') return [/*'username',*/'email', 'firstName', 'lastName', 'password'];
  }
  preparePersistedRegistrationData(data) {
    return objectWithout(data, ['password']);
  }
}