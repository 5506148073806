import { InstructionHelper } from '@core/frontend-shared';
import { BaseInstruction } from './base.instruction';
import { getClassName } from '@core/shared/class-name';
export class LayoutInstruction extends BaseInstruction {
  constructor() {
    super(...arguments);
    this.isInput = false;
    this.useFieldWrapper = false;
    this.pluginInstructions = [];
  }
  prepareInstructionListsInConfig(config) {
    if (!config.instructions) throw new Error(getClassName(this) + ' error: children config prop must be set!');
    config.instructions = InstructionHelper.prepareInstructionsList(config.instructions);
  }
  setConfig(config) {
    this.prepareInstructionListsInConfig(config);
    super.setConfig(config);
  }
  createComponentInputsMap(formGroup, formService) {
    return super.createComponentInputsMap(formGroup, formService);
  }
  addPluginInstructions(instructions) {
    this.pluginInstructions.push(...instructions);
  }
  resetPluginInstructions() {
    this.pluginInstructions = [];
  }
  getSlotId() {
    return this.config?.slotId || null;
  }
  getInstructions() {
    return [...this.config.instructions, ...this.pluginInstructions];
  }
  addDynamicInputValues(map, sourceMapName, formGroup, formService) {
    map['formGroup'] = formGroup;
    map['formService'] = formService;
    if (sourceMapName === 'inputMap') {
      // override config.instructions array with array with added plugininstructions
      map['instructions'] = this.getInstructions();
    }
    return map;
  }
}