import { DynamicFieldInstruction } from './shared';
export class InputPasswordRepeatedInstruction extends DynamicFieldInstruction {
  getDefaults() {
    return {
      usePlaceholders: false,
      orientation: 'horizontal',
      showToggleVisibilityButton: true,
      showPasswordGenerateButton: true
    };
  }
  constructor(config) {
    super();
    this.isInput = false;
    this.useFieldWrapper = false;
    this.attributeMap = {};
    this.inputMap = {
      usePlaceholders: 'usePlaceholders',
      orientation: 'orientation',
      showToggleVisibilityButton: 'showToggleVisibilityButton',
      showPasswordGenerateButton: 'showPasswordGenerateButton',
      formControlName1: "formControlName1",
      formControlName2: "formControlName2"
    };
    this.setConfig(config);
  }
  load() {
    return import( /* webpackChunkName: "ui-input-password" */
    /* webpackMode: "lazy" */
    '@common/ui/frontend-shared/input-password').then(bundle => {
      return {
        component: bundle.InputPasswordComponent,
        module: bundle.UiInputPasswordModule
      };
    });
  }
  addDynamicInputValues(map, sourceMapName, formGroup, formService) {
    if (!formService) throw new Error('InputPasswordRepeatedInstruction can only be used with a FormService-based form!');
    map['formService'] = formService;
    return map;
  }
}