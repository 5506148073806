import { BaseFeature, FeatureType } from '@core/shared/feature';
import { AllowEmpty, IsEmail, Matches } from '@core/shared/model';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
export class MpfUsersBaseFeature extends BaseFeature {
  static {
    this.featureInfo = {
      context: {"package":"pkg-mpf-users-feature","featureId":"mpf-users"},
      icons: [faBuilding],
      abilityActions: false,
      plugins: [
      // {forPlatform:'frontend', type:FeatureType.Permanent, pluginClassName:'MpfUsersFrontendFeature'},
      {
        forPlatform: 'admin',
        type: FeatureType.Permanent,
        pluginClassName: 'MpfUsersFrontendAdminFeature'
      }, {
        forPlatform: 'backend-admin',
        type: FeatureType.Permanent,
        pluginClassName: 'MpfUsersBackendAdminFeature'
      }]
    };
  }
  getSharedDefinition() {
    return {
      routerLinkResolvers: {
        frontend: {
          'profile': () => {
            // TODO: https://app.clickup.com/t/86bw2gx6b
            return '/user/profile';
          }
        }
      },
      virtualProperties: {
        'CompanyItem': [{
          name: 'customerId',
          canRead: ['managerOfCompany', 'admin'],
          canEdit: ['admin']
        }, {
          name: 'contact_phone',
          canRead: ['managerOfCompany', 'admin'],
          canEdit: ['admin'],
          validators: [AllowEmpty(), Matches(/^[\d\s+\-()/]{3,}$/, {
            message: "Bitte geben Sie eine gültige Telefonnummer ein!"
          })]
        }, {
          name: 'contact_name',
          canRead: ['managerOfCompany', 'admin'],
          canEdit: ['admin']
        }, {
          name: 'contact_email',
          canRead: ['managerOfCompany', 'admin'],
          canEdit: ['admin'],
          validators: [AllowEmpty(), IsEmail()]
        }, {
          name: 'invoicing_address',
          canRead: ['admin'],
          canEdit: ['admin']
        }]
      }
    };
  }
}