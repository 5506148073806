import { MpfPaymentMembershipPlugin } from '@mpf/membership-plugin-payment/shared';
import { MembershipRegistrationPluginSummaryComponent } from '@common/membership/frontend-shared';
import { MembershipRegistrationFormMpfPaymentComponent } from './registration-form.component';
import { VIRTUAL_PROPS_ACCESSOR } from '@core/shared/virtual-properties';
import { RouterLinkResolverService } from '@core/shared/feature';
/** plugin only for viewing support of memberships of legacy "payment" type */
export class MpfPaymentFrontendMembershipPlugin extends MpfPaymentMembershipPlugin {
  getRegistrationComponent() {
    return MembershipRegistrationFormMpfPaymentComponent;
  }
  getSummaryComponent() {
    return MembershipRegistrationPluginSummaryComponent;
  }
  getAccountData(data) {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      password2: data.password2,
      email: data.email,
      username: data.email,
      // password will be generated on server side
      [VIRTUAL_PROPS_ACCESSOR]: {
        phone: data.phone,
        allow3rdPartyContact: data.allow3rdPartyContact
      }
    };
  }
  getDataToPersist(data) {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      address: data.address,
      zipcode: data.zipcode,
      city: data.city
    };
  }
  getCategorization(data) {
    return null;
  }
  getRegistrationCompletedButton() {
    const resolver = this.injector.get(RouterLinkResolverService);
    const link = resolver.resolveRouterLink('frontend', 'courses');
    return {
      label: "Weiter zu den Kursen",
      link
    };
  }
}