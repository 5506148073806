import { BaseFeature, FeatureType } from '@core/shared/feature';
import { faServer } from '@fortawesome/free-solid-svg-icons';
export class ServerlogsBaseFeature extends BaseFeature {
  static {
    this.featureInfo = {
      context: {"package":"pkg-common-serverlogs-feature","featureId":"common-serverlogs"},
      icons: [faServer],
      abilityActions: false,
      plugins: [
      // {forPlatform:'frontend', type:FeatureType.Permanent, pluginClassName:'ServerlogsFrontendFeature'},
      {
        forPlatform: 'admin',
        type: FeatureType.Permanent,
        pluginClassName: 'ServerlogsFrontendAdminFeature'
      },
      // {forPlatform:'backend', type:FeatureType.Permanent, pluginClassName:'ServerlogsBackendFeature'},
      {
        forPlatform: 'backend-admin',
        type: FeatureType.Permanent,
        pluginClassName: 'ServerlogsBackendAdminFeature'
      }]
    };
  }
  getSharedDefinition() {
    return {
      routerLinkResolvers: {
        frontend: {}
      }
    };
  }
}