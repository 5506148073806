import { ItemRestraints } from '@core/shared/restraints';
export class NotificationRecipientRestraints extends ItemRestraints {
  canViewItem(item) {
    if (this.isExecutedBySystem()) return true;
    if (this.currentUserIsOwner(item)) return true;
    return this.requireRole('ADMINISTRATOR');
  }
  canCreateItem(item) {
    if (this.isExecutedBySystem()) return true;
    if (this.currentUserIsOwner(item)) return true;
    // if(this.requireRole('ADMINISTRATOR')===true) return true;
    // TODO: allow Managers to create items for their team members / currentUserManagesOwner
    return this.requireRole('ADMINISTRATOR');
  }
  canToggleItem(item) {
    if (this.isExecutedBySystem()) return true;
    if (this.currentUserIsOwner(item)) return true;
    return this.requireRole('ADMINISTRATOR');
  }
  canUpdateItem(item, oldItem) {
    if (this.isExecutedBySystem()) return true;
    if (this.currentUserIsOwner(item)) return true;
    return this.requireRole('ADMINISTRATOR');
  }
  canRemoveItem(item) {
    if (this.isExecutedBySystem()) return true;
    if (this.currentUserIsOwner(item)) return true;
    return this.requireRole('ADMINISTRATOR');
  }
}